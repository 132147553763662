<template>
    <div>
        <full-overlay :show="loading" />
        <b-table :items="list" :fields="fields" striped class="mt-3">
            <template
                #cell(participant)="{
                    item: {
                        spectacle: {
                            participant: { name },
                        },
                    },
                }"
            >
                <div>{{ name.ru }}</div>
                <div>{{ name.en }}</div>
            </template>
            <template
                #cell(name)="{
                    item: {
                        spectacle: { name },
                    },
                }"
            >
                <div>{{ name.ru }}</div>
                <div>{{ name.en }}</div>
            </template>
            <template #cell(settings)="{ item: { id } }">
                <b-button-group>
                    <b-button @click="edit(id)">Редактировать</b-button>
                    <b-button @click="content(id)">Описание</b-button>
                    <b-button @click="remove(id)" variant="danger">
                        Удалить
                    </b-button>
                </b-button-group>
            </template>
        </b-table>
        <b-modal
            title="Редактирование"
            v-model="modal.show"
            hide-footer
            size="xl"
        >
            <b-form @submit.prevent="send">
                <b-form-row>
                    <b-col>
                        <b-form-group label="Описание">
                            <b-form-textarea v-model="modal.description.ru" />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="[EN] Описание">
                            <b-form-textarea v-model="modal.description.en" />
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col>
                        <b-form-group label="Режим описания">
                            <b-form-radio-group
                                v-model="modal.contentMode"
                                :options="mode"
                                stacked
                            />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Режим расширенного описания">
                            <b-form-radio-group
                                v-model="modal.descrMode"
                                :options="mode"
                                stacked
                            />
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-group label="Загрузка постера">
                    <b-form-file
                        v-model="modal.poster"
                        accept="image/jpeg, image/png"
                    />
                </b-form-group>
                <div class="poster" v-if="modal.hasPoster">
                    <img
                        :src="`${poster}/${modal.id}/poster.jpg?${Date.now()}`"
                    />
                    <b-button
                        @click="modal.hasPoster = false"
                        variant="danger"
                        class="mt-3"
                    >
                        Удалить
                    </b-button>
                </div>
                <b-button type="submit" class="mt-1">Сохранить</b-button>
            </b-form>
        </b-modal>
        <content-editor
            ref="editor"
            get-url="/festival/spectacle/:id/content"
            send-url="/festival/spectacle/:id/content"
            :media-url="poster"
        />
    </div>
</template> 

<style lang="scss" scoped>
.poster {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
    img {
        max-width: 700px;
        max-height: 600px;
    }
}
</style>

<script>
import FullOverlay from "@c/FullOverlay.vue";
import ContentEditor from "@c/ContentEditor.vue";
export default {
    name: "FestivalSpectacle",
    components: {
        FullOverlay,
        ContentEditor,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            list: [],
            loading: false,
            fields: [
                {
                    key: "id",
                    label: "#",
                },
                {
                    key: "participant",
                    label: "Участник",
                },
                {
                    key: "name",
                    label: "Спектакль",
                },
                {
                    key: "settings",
                    label: "",
                },
            ],
            poster: "",
            modal: this.getModal(),
        };
    },
    mounted() {
        this.update();
    },
    computed: {
        mode: () => [
            { value: 0, text: "После основного" },
            { value: 1, text: "До основного" },
            { value: 2, text: "Вместо основного" },
        ],
    },
    methods: {
        async update() {
            this.loading = true;
            const { list, poster } = await this.$fetchAdmin(
                `/festival/${this.id}/spectacle`
            ).then((r) => r.json());
            this.list = list;
            this.poster = `${this.$store.state.api}${poster}`;
            this.loading = false;
        },
        getModal: () => ({
            id: 0,
            show: false,
            description: {
                ru: "",
                en: "",
            },
            descrMode: 0,
            contentMode: 0,
            poster: null,
            hasPoster: false,
        }),
        async edit(id) {
            this.loading = true;
            const { one } = await this.$fetchAdmin(
                `/festival/spectacle/${id}`
            ).then((r) => r.json());
            if (!one.description) {
                one.description = {
                    ru: "",
                    en: "",
                };
            }
            this.modal = Object.assign(this.getModal(), one);
            this.modal.show = true;
            this.loading = false;
        },
        async send() {
            this.loading = true;
            const body = new FormData();
            body.append("description[ru]", this.modal.description.ru);
            body.append("description[en]", this.modal.description.en);
            body.append("descrMode", this.modal.descrMode);
            body.append("contentMode", this.modal.contentMode);
            body.append("hasPoster", this.modal.hasPoster ? 1 : 0);
            if (this.modal.poster) {
                body.append("poster", this.modal.poster);
            }
            await this.$fetchAdmin(`/festival/spectacle/${this.modal.id}`, {
                method: "POST",
                body,
            }).then((r) => r.json());
            this.loading = false;
            this.modal.show = false;
        },
        content(id) {
            this.$refs.editor.open(id);
        },
        async remove(id) {
            const st = await this.$bvModal.msgBoxConfirm(
                "Подтвердите удаление"
            );
            if (!st) {
                return false;
            }
            this.loading = true;
            await this.$fetchAdmin(`/festival/spectacle/${id}`, {
                method: "DELETE",
            }).then((r) => r.json());
            this.loading = false;
            this.update();
        },
    },
};
</script>